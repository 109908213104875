import { ExclamationCircleIcon, XIcon } from "@heroicons/react/solid";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Copyclaim from "../components/Copyclaim";
import authService from "../services/auth.service";

function Login() {
  const navigate = useNavigate();
  const SignInSchema = yup.object().shape({
    email: yup
      .string()
      .email("Bitte geben Sie eine gültige E-Mail ein.")
      .required("Dieses Feld darf nicht leer sein"),
    password: yup.string().required("Dieses Feld darf nicht leer sein"),
  });

  const [loading, setLoading] = useState(false);

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-cover align-middle">
      <div className="w-96 rounded-md bg-white py-6 px-8 shadow-lg">
        <h1 className="text-center text-3xl">Anmelden</h1>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values) => {
            setLoading(true);
            let authRes = await authService.login(
              values.email,
              values.password
            );

            if (authRes !== "") {
              setLoading(false);
              return toast.error(authRes);
            }

            navigate("/orga");
          }}
          validationSchema={SignInSchema}
          validateOnBlur
          validateOnChange
        >
          <Form className="mt-6 w-full space-y-6">
            <div className="flex w-full flex-col">
              <label className="text-xs font-bold text-slate-500">E-Mail</label>
              <Field
                required
                autoComplete="email"
                className="mt-2 w-full rounded-md border border-slate-300 px-3 py-2 transition-all focus:border-slate-400"
                placeholder="max@mustermann.de"
                name="email"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="email"
              />
            </div>

            <div className="flex w-full flex-col">
              <label className="text-xs font-bold text-slate-500">
                Passwort
              </label>
              <Field
                required
                autoComplete="password"
                type="password"
                className="mt-2 w-full rounded-md border border-slate-300 px-3 py-2 transition-all focus:border-slate-400"
                placeholder="·············"
                name="password"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="password"
              />
            </div>

            <div className="flex justify-between">
              <div className="flex items-center">
                <input type="checkbox" className="rounded-full" />
                <label className="ml-2 block text-sm">
                  Angemeldet bleiben?
                </label>
              </div>
              <Link to="/forgotPassword" className="text-sm text-sky-600">
                Passwort vergessen?
              </Link>
            </div>

            {loading ? (
              <button
                disabled
                type="submit"
                className="flex w-full flex-row justify-center rounded-md bg-sky-700 py-2 px-3 align-middle text-white transition-all hover:bg-sky-800 hover:shadow-lg  disabled:bg-slate-600 disabled:text-white"
              >
                Absenden{" "}
                <img
                  src="/loading_white.svg"
                  className="ml-4 h-6 w-6 stroke-white"
                  alt="Ladeanimation"
                />
              </button>
            ) : (
              <div className="">
                <button
                  type="submit"
                  className="w-full rounded-md bg-sky-700 py-2 px-3 text-white transition-all  hover:bg-sky-800 hover:shadow-lg"
                >
                  Absenden
                </button>
                <button
                  onClick={() => navigate("../register")}
                  className="mt-2 w-full rounded-md border py-2 px-3 text-sm text-slate-500 transition-all hover:shadow-lg"
                >
                  Registrieren
                </button>
              </div>
            )}
          </Form>
        </Formik>
      </div>
      <Copyclaim />
    </div>
  );
}

export default Login;
