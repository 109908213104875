import { HomeIcon, MicrophoneIcon, PlusIcon } from "@heroicons/react/solid";
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";

function Radios() {
  const params = useParams();
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="w-2/3 mt-12">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl flex">
              <MicrophoneIcon className="w-6 mr-3 text-slate-400" />
              Funkgeräte
            </h1>
            <button className="bg-sky-700 text-white rounded-md px-6 py-2 flex items-center hover:shadow-xl hover:bg-sky-800 active:bg-sky-900 transition-all text-sm">
              <PlusIcon className="w-5 mr-2" /> Neues Funkgerät
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Radios;
