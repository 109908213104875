import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import GenericHeader from "../../components/GenericHeader";
import Loading from "../../components/Loading";
import dataService from "../../services/data.service";

const fieldStyling =
  "w-full px-3 py-2 border border-slate-200 rounded-md mt-2 focus:border-slate-500 transition-all";

function CreateOrga() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <GenericHeader />
      <div className="px-6 pt-24 sm:px-8 md:px-16">
        Organisation erstellen
        <Formik
          initialValues={{}}
          onSubmit={async (values) => {
            setLoading(true);
            let response = await dataService.landlordCreateOrganization(values);
            setLoading(false);
            if (response.status !== 201) return toast.error(response.data);
          }}
        >
          {loading ? <Loading /> : <CreateOrgaForm />}
        </Formik>
      </div>
    </>
  );
}

function CreateOrgaForm() {
  return (
    <Form>
      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">Name*</span>
        <Field
          required
          autocomplete="alarmlist_name"
          className={fieldStyling}
          type="name"
          name="name"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="name"
        />
      </label>
      <hr />
      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">E-Mail*</span>
        <Field
          required
          autocomplete="email"
          className={fieldStyling}
          type="email"
          name="email"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="email"
        />
      </label>
      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">Telefonnummer*</span>
        <Field
          required
          autocomplete="phone"
          className={fieldStyling}
          type="phone"
          name="phone"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="phone"
        />
      </label>
      <hr />

      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">Straße*</span>
        <Field
          required
          autocomplete="street"
          className={fieldStyling}
          type="street"
          name="street"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="street"
        />
      </label>
      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">Haus-Nr*</span>
        <Field
          required
          autocomplete="house_nr"
          className={fieldStyling}
          type="house_nr"
          name="house_nr"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="house_nr"
        />
      </label>
      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">Stadt*</span>
        <Field
          required
          autocomplete="city"
          className={fieldStyling}
          type="city"
          name="city"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="city"
        />
      </label>

      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">PLZ*</span>
        <Field
          required
          autocomplete="zip"
          className={fieldStyling}
          type="zip"
          name="zip"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="zip"
        />
      </label>

      <hr />

      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">
          Rechnung: E-Mail*
        </span>
        <Field
          required
          autocomplete="invoice_email"
          className={fieldStyling}
          type="invoice_email"
          name="invoice_email"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="invoice_email"
        />
      </label>

      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">
          Rechnung: Stadt*
        </span>
        <Field
          required
          autocomplete="invoice_city"
          className={fieldStyling}
          type="invoice_city"
          name="invoice_city"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="city"
        />
      </label>
      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">
          Rechnung: Straße*
        </span>
        <Field
          required
          autocomplete="invoice_street"
          className={fieldStyling}
          type="invoice_street"
          name="invoice_street"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="invoice_street"
        />
      </label>
      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">
          Rechnung: Haus-Nr*
        </span>
        <Field
          required
          autocomplete="invoice_house_nr"
          className={fieldStyling}
          type="invoice_house_nr"
          name="invoice_house_nr"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="invoice_house_nr"
        />
      </label>

      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">Rechnung: PLZ*</span>
        <Field
          required
          autocomplete="invoice_zip"
          className={fieldStyling}
          type="invoice_zip"
          name="invoice_zip"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="invoice_zip"
        />
      </label>

      <label className="col-span-2 my-3 block">
        <span className="text-xs font-bold text-slate-500">
          Rechnung: Land*
        </span>
        <Field
          required
          autocomplete="invoice_country"
          className={fieldStyling}
          type="invoice_country"
          name="invoice_country"
        />
        <ErrorMessage
          component="p"
          className="mt-2 text-xs font-semibold text-red-400"
          name="invoice_country"
        />
      </label>

      <button
        className="rounded-md bg-blue-500 px-4 py-2 text-white"
        type="submit"
      >
        Erstellen
      </button>
    </Form>
  );
}

export default CreateOrga;
