import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import dataService from "../../services/data.service";
import "yup-phone";
import RegisterStep0 from "./Step0";
import RegisterStep1 from "./Step1";
import RegisterError from "./RegisterError";
import RegisterStep2 from "./Step2";
import RegisterSuccess from "./RegisterSuccess";
import RegisterLoading from "./RegisterLoading";
import Navbar from "../../components/Navbar";
import AlarmMethod from "../../interfaces/AlarmMethod";
import User from "../../interfaces/User";

function Register() {
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState(0);
  const [orgaName, setOrgaName] = useState("");

  async function init() {
    let token = searchParams.get("token");

    if (!token) {
      return setStep(5);
    }

    dataService
      .getInviteCodeData(token)
      .then((res) => {
        if (res.status !== 200) return setStep(5);
        setOrgaName(res.data.organization.name);
        initalValues.email = res.data.email;
        return setStep(1);
      })
      .catch(() => {
        return setStep(5);
      });
  }

  useEffect(() => {
    init();
  }, []);

  function forward() {
    if (step !== 3) setStep(step + 1);
  }

  function backward() {
    if (step !== 0) setStep(step - 1);
  }

  if (!searchParams.get("token")) {
    setStep(5);
  }

  interface FormValues {
    email: string;
    firstname: string;
    lastname: string;
    username: string;
    phone: string;
    house_nr: string;
    zip: string;
    city: string;
    birthday: string;
    street: string;
    password: string;
    password_confirm: string;
  }

  const initalValues: FormValues = {
    firstname: "",
    lastname: "",
    username: "",
    phone: "",
    email: "",
    house_nr: "",
    zip: "",
    city: "",
    birthday: "",
    street: "",
    password: "",
    password_confirm: "",
  };

  const NewUserSchema = yup.object().shape({
    firstname: yup.string().required("Dieses Feld darf nicht leer sein."),
    lastname: yup.string().required("Dieses Feld darf nicht leer sein."),
    username: yup.string().required("Dieses Feld darf nicht leer sein."),
    phone: yup
      .string()
      .required("Dieses Feld darf nicht leer sein.")
      .phone("DE", true, "Bitte geben Sie eine gültige Telefonnummer ein."),
    email: yup.string().email("Bitte geben Sie eine gültige E-Mail ein."),
    house_nr: yup.string().required("Dieses Feld darf nicht leer sein."),
    zip: yup.string().required("Dieses Feld darf nicht leer sein."),
    city: yup.string().required("Dieses Feld darf nicht leer sein."),
    street: yup.string().required("Dieses Feld darf nicht leer sein."),
    birthday: yup.date().required("Dieses Feld darf nicht leer sein."),
    password: yup
      .string()
      .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
      .required("Dieses Feld darf nicht leer sein."),
    password_confirm: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Die Passwörter müssen übereinstimmen.",
      ),
  });

  function _renderStepContent(step: number) {
    switch (step) {
      case 0:
        return <RegisterLoading />;
      case 1:
        return (
          <RegisterStep0
            onForward={forward}
            onBackward={backward}
            orgaName={orgaName}
            orgaImage={"Test"}
          />
        );

      case 2:
        return <RegisterStep1 onForward={forward} onBackward={backward} />;

      case 3:
        return <RegisterStep2 onForward={forward} onBackward={backward} />;

      case 4:
        return <RegisterSuccess />;

      case 5:
        return <RegisterError />;
    }
  }

  async function submit(values: FormValues) {
    setStep(0);

    let _user: User = {
      image: "",
      firstname: values.firstname,
      lastname: values.lastname,
      username: values.username,
      phone: values.phone,
      email: values.email,
      street: values.street,
      house_nr: values.house_nr,
      zip: values.zip,
      city: values.city,
      birthday: +values.birthday.split("-")[2],
      birthmonth: +values.birthday.split("-")[1],
      birthyear: +values.birthday.split("-")[0],
      password: values.password,
    };

    dataService
      .registerUser(_user, searchParams.get("token") ?? "")
      .then((res) => {
        console.log(res);
        if (res.status === 200) return setStep(4);
        else return setStep(5);
      });
  }

  return (
    <>
      <Navbar />
      <div className="flex h-screen flex-row">
        <div className="hidden md:block">
          <img
            src={"/banner_phone.png"}
            className="h-screen w-auto object-cover"
            alt="EinsatzAlarm in Verwendung"
          />
        </div>
        <Formik
          initialValues={initalValues}
          onSubmit={(values) => submit(values)}
          validationSchema={NewUserSchema}
          validateOnBlur
          validateOnChange
        >
          {_renderStepContent(step)}
        </Formik>
      </div>
    </>
  );
}

export default Register;
