import {
  ChevronRightIcon,
  CogIcon,
  LogoutIcon,
  LightningBoltIcon,
} from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Copyclaim from "../components/Copyclaim";
import Loading from "../components/Loading";
import authService from "../services/auth.service";
import dataService from "../services/data.service";

function OrgaSelection() {
  const [orgas, setOrgas] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const user = authService.getCurrentUser();
  async function fetchData() {
    dataService
      .getOrgas()
      .then((res) => {
        res.data.forEach((element: any) => {
          setOrgas((prevState) => [...prevState, element]);
        });
      })
      .catch((err) => {
        toast.error(err);
      });
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex min-h-screen flex-col items-center justify-center align-middle">
      <div className="w-96 rounded-md bg-white py-6 px-8 shadow-lg">
        <div>
          <h1 className="text-center text-3xl">
            Willkommen zurück, {user?.firstname}
          </h1>
          <p className="mt-3 text-center text-sm text-slate-500">
            Bitte wählen Sie Ihre Organisation
          </p>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="mt-6">
            {orgas
              .filter((orga) => orga.state === "accepted")
              .map((orga) => (
                <Link
                  key={orga.organization.id}
                  to={"/orga/" + orga.organization.id + "/dashboard"}
                  className="my-3 flex justify-between rounded-md bg-slate-50 py-3 px-6 shadow-md transition-all hover:shadow-lg"
                >
                  <p>{orga.organization.name}</p>
                  <ChevronRightIcon className="w-4" />
                </Link>
              ))}
            <hr />
            <div className="flex gap-4">
              <Link
                to={"../profile"}
                className="my-3 flex w-full items-center justify-start gap-4 rounded-md bg-slate-50 py-3 px-6 shadow-md transition-all hover:shadow-lg"
              >
                <CogIcon className="w-4 text-slate-600" />
                <p className="text-slate-600">Einstellungen</p>
              </Link>
              <button
                onClick={authService.logout}
                className="my-3 flex w-min items-center justify-center rounded-md bg-slate-50 py-3 px-6 shadow-md transition-all hover:shadow-lg"
              >
                <LogoutIcon className="w-4 text-red-600" />
              </button>
            </div>
            {user?.landlord && (
              <Link
                to={"../landlord"}
                className="my-3 flex w-full items-center justify-start gap-4 rounded-md bg-slate-50 py-3 px-6 shadow-md transition-all hover:shadow-lg"
              >
                <LightningBoltIcon className="w-4 text-slate-600" />
                <p className="text-slate-600">System</p>
              </Link>
            )}
          </div>
        )}
      </div>

      <Copyclaim />
    </div>
  );
}

export default OrgaSelection;
