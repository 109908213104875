import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import AAO from "../../../interfaces/AAO";
import dataService from "../../../services/data.service";

function OrgaSettingsAAO() {
  const { orgaId } = useParams();
  const [aaos, setAAOs] = useState<AAO[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    setLoading(true);
    let response = await dataService.getAllAAO(orgaId ?? "");
    if (response.status !== 200)
      return toast.error("Fehler beim Laden der Daten.");
    response.data.forEach((aao: AAO) => {
      setAAOs((prevState) => [...prevState, aao]);
    });
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="grid w-full grid-cols-4 gap-x-10 gap-y-2">
          <Link
            to={"../aao/createAAO"}
            className="flex items-center justify-center rounded-md bg-sky-700 px-6 py-2 align-middle text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900"
          >
            <PlusIcon className="mr-2 w-5" /> Neue AAO
          </Link>
          {aaos.map((aao) => {
            return (
              <Link
                to={"../aao/" + aao.id}
                className="flex justify-center rounded-md bg-white py-2 px-6 align-middle shadow transition-all hover:bg-zinc-100 hover:shadow-md active:bg-zinc-200 active:shadow-lg"
              >
                <PencilIcon className="mr-2 h-6 text-blue-700" /> {aao.name}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default OrgaSettingsAAO;
