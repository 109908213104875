import Copyclaim from "../components/Copyclaim";

function NotFound() {
  return (
    <div className="flex h-screen justify-center items-center align-middle">
      <div>
        <h1 className="text-3xl text-slate-800">Oh!</h1>
        <p className="text-slate-600">
          Leider wurde diese Seite nicht gefunden.
        </p>
        <Copyclaim />
      </div>
    </div>
  );
}

export default NotFound;
