function Loading() {
  return (
    <div className="text-center mt-12 w-full items-center flex flex-col">
      <img src="/loading.svg" alt="Ladeanimation" />
      <p className="tracking-wider text-slate-600 my-4">Lade Daten...</p>
    </div>
  );
}

export default Loading;
