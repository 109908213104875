import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import GenericHeader from "../components/GenericHeader";
import Orga from "../interfaces/Orga";
import dataService from "../services/data.service";

function Landlord() {
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState<Orga[]>([]);

  useEffect(() => {
    fetchOrganisations();
  }, []);

  async function fetchOrganisations() {
    setLoading(true);
    try {
      let response = await dataService.landlordGetOrganisations();
      response.data.forEach((element: Orga) => {
        setOrganizations((prevState) => [...prevState, element]);
      });
    } catch (err) {
      toast.error("Ein Fehler ist aufgetreten: " + err);
    }
  }

  return (
    <>
      <GenericHeader />
      <div className="px-6 pt-24 sm:px-8 md:px-16">
        <div id="orgas">
          <div className="flex justify-between">
            <p>Organisationen</p>{" "}
            <Link
              className="rounded-md bg-blue-500 px-4 py-2 text-white"
              to={"createOrga"}
            >
              Erstellen
            </Link>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow-md sm:rounded-lg">
                  <table className="min-w-full">
                    <thead className="bg-zinc-100">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                        >
                          Helfer
                        </th>
                        <th scope="col" className="relative py-3 px-6">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {organizations.map((orga) => {
                        return (
                          <tr className="border-b bg-white odd:bg-white even:bg-gray-50">
                            <td className="whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 ">
                              {orga.name}
                            </td>
                            <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                              Test
                            </td>
                            <td className="whitespace-nowrap py-4 px-6 text-right text-sm font-medium">
                              <a
                                className="text-blue-600 hover:text-blue-900"
                                href="https://google.de"
                              >
                                Bearbeiten
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landlord;
