import {
  HomeIcon,
  LightningBoltIcon,
  TruckIcon,
  MicrophoneIcon,
  UserGroupIcon,
  ArchiveIcon,
  BadgeCheckIcon,
  BellIcon,
  CogIcon,
  LogoutIcon,
  ClipboardListIcon,
  ViewListIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import authService from "../services/auth.service";

const Sidebar = () => {
  const params = useParams();
  const [collapsed, setCollapsed] = useState(false);

  const user = authService.getCurrentUser();

  let inactive =
    "px-6 py-2 font-semibold rounded-r-md text-bold text-red hover:bg-white hover:border-l-4 border-cyan-700 transition-all flex items-center cursor-pointer my-3";
  let active = inactive + " bg-white border-l-4";

  /** 
  if (collapsed) {
    return (
      <div className="flex">
        <div className="w-18 fixed flex h-screen flex-col justify-between border-r-2 py-5 transition-all">
          <nav>
            <p className="mx-6 mt-4 font-sans text-xl">
              E<span className="text-red-600">S</span>
            </p>
            <NavLink
              to={"/orga/" + params.orgaId + "/dashboard"}
              className={({ isActive }) => (isActive ? active : inactive)}
            >
              <HomeIcon className="h-5 w-5 text-slate-400" />
            </NavLink>

            <NavLink
              to={"/orga/" + params.orgaId + "/users"}
              className={({ isActive }) => (isActive ? active : inactive)}
            >
              <UserGroupIcon className="h-5 w-5 text-slate-400" />
            </NavLink>
            <hr className="flex-grow" />

            <NavLink
              to={"/orga/" + params.orgaId + "/alarmlist"}
              className={({ isActive }) => (isActive ? active : inactive)}
            >
              <ClipboardListIcon className="h-5 w-5 text-slate-400" />
            </NavLink>
            <NavLink
              to={"/orga/" + params.orgaId + "/alarm"}
              className={({ isActive }) => (isActive ? active : inactive)}
            >
              <BellIcon className="h-5 w-5 text-red-400" />
            </NavLink>

            <hr className="flex-grow" />

            <NavLink
              to={"/orga/" + params.orgaId + "/settings"}
              className={({ isActive }) => (isActive ? active : inactive)}
            >
              <CogIcon className="h-5 w-5 text-slate-400" />
            </NavLink>
            <hr />
          </nav>
          <ChevronRightIcon
            className="mx-auto h-6 w-6 cursor-pointer text-slate-600"
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
      </div>
    );
  }*/

  function collapsedContent() {
    return (
      <>
        <nav>
          <p className="mx-6 mt-4 font-sans text-xl">
            E<span className="text-red-600">S</span>
          </p>
          <NavLink
            to={"/orga/" + params.orgaId + "/dashboard"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <HomeIcon className="h-5 w-5 text-slate-400" />
          </NavLink>

          <NavLink
            to={"/orga/" + params.orgaId + "/users"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <UserGroupIcon className="h-5 w-5 text-slate-400" />
          </NavLink>
          <hr className="flex-grow" />

          <NavLink
            to={"/orga/" + params.orgaId + "/alarmlist"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <ClipboardListIcon className="h-5 w-5 text-slate-400" />
          </NavLink>
          <NavLink
            to={"/orga/" + params.orgaId + "/alarm"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <BellIcon className="h-5 w-5 text-red-400" />
          </NavLink>

          <hr className="flex-grow" />

          <NavLink
            to={"/orga/" + params.orgaId + "/settings"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <CogIcon className="h-5 w-5 text-slate-400" />
          </NavLink>
          <hr />
        </nav>
        <div>
          <ChevronRightIcon
            className="mx-auto h-6 w-6 cursor-pointer text-slate-600"
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
      </>
    );
  }

  function uncollapsedContent() {
    return (
      <>
        <nav className=" pr-4">
          <p className="mx-6 mt-4 font-sans text-xl">
            Einsatz<span className="text-red-600">Alarm</span>
          </p>
          <NavLink
            to={"/orga/" + params.orgaId + "/dashboard"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <HomeIcon className="mr-2 h-4 w-4 text-slate-400" />
            Dashboard
          </NavLink>

          <NavLink
            to={"/orga/" + params.orgaId + "/users"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <UserGroupIcon className="mr-2 h-4 w-4 text-slate-400" />
            Helfer
          </NavLink>
          <div className="flex items-center pl-4">
            <p className="mr-2 text-sm text-slate-400">ALARM</p>
            <hr className="flex-grow" />
          </div>

          <NavLink
            to={"/orga/" + params.orgaId + "/alarmlist"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <ClipboardListIcon className="mr-2 h-4 w-4 text-slate-400" />
            Alarmlisten
          </NavLink>
          <NavLink
            to={"/orga/" + params.orgaId + "/alarm"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <BellIcon className="mr-2 h-4 w-4 text-red-400" />
            Alarmieren
          </NavLink>
          <div className="flex items-center pl-4">
            <p className="mr-2 text-sm text-slate-400">ADMINISTRATION</p>
            <hr className="flex-grow" />
          </div>
          <NavLink
            to={"/orga/" + params.orgaId + "/settings"}
            className={({ isActive }) => (isActive ? active : inactive)}
          >
            <CogIcon className="mr-2 h-4 w-4 text-slate-400" />
            Organisation
          </NavLink>
          <hr />
        </nav>
        <div className="mt-3 flex flex-col space-y-2 px-3">
          <NavLink
            className="cursor-pointer items-center rounded-md bg-white px-4 py-2 font-semibold transition-all hover:shadow active:shadow-md"
            to={"/profile/"}
          >
            <p className="text-sm text-neutral-400">Angemeldet als:</p>
            <p className="">
              {user?.firstname} {user?.lastname}
            </p>
          </NavLink>

          {/**<ChevronLeftIcon
            className="mx-auto h-6 w-6 cursor-pointer text-slate-600"
            onClick={() => setCollapsed(!collapsed)}
          />**/}
        </div>
      </>
    );
  }

  return (
    <div className="flex">
      <div
        className={
          collapsed
            ? "fixed flex h-screen w-20 flex-col justify-between border-r-2 py-5 transition-all"
            : "fixed flex h-screen w-64 flex-col justify-between border-r-2 py-5 shadow-xl transition-all"
        }
      >
        {collapsed ? collapsedContent() : uncollapsedContent()}
      </div>
      <div
        className={collapsed ? "ml-20 transition-all" : "ml-64 transition-all"}
      ></div>
    </div>
  );
};

export default Sidebar;
