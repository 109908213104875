import {
  FilterIcon,
  InformationCircleIcon,
  PlusIcon,
  SaveIcon,
} from "@heroicons/react/solid";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import User from "../../interfaces/User";
import dataService from "../../services/data.service";
import { toast } from "react-toastify";

function CreateAlarmlist() {
  const { orgaId } = useParams();
  const [users, setUsers] = useState<User[]>([]);
  const [availableUsers, setAvailableUsers] = useState<User[]>(users);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  let navigate = useNavigate();

  const filter = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = users.filter((user) => {
        return (
          user.firstname.toLowerCase().includes(keyword.toLowerCase()) ||
          user.lastname.toLowerCase().includes(keyword.toLowerCase()) ||
          user.username.toLowerCase().includes(keyword.toLowerCase())
        );
      });

      setAvailableUsers(results);
    } else {
      setAvailableUsers(users);
    }

    setSearch(keyword);
  };

  useEffect(() => {
    dataService.getUsers(orgaId ?? "").then((res) => {
      if (res.status === 200) {
        res.data.userByOrgaId.forEach((user: User) => {
          setUsers((prevState) => [...prevState, user]);
          setAvailableUsers((prevState) => [...prevState, user]);
        });
        setLoading(false);
      } else {
        alert("Fehler beim Laden (Fehler: " + res.status + ")");
      }
    });
  }, []);

  const checkAll = (e: any) => {
    const toggle = e.target.value;

    if (toggle) {
    }
  };

  const fieldStyling =
    "w-full px-3 py-2 border border-slate-200 rounded-md mt-2 focus:border-slate-500 transition-all";

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="mt-12 w-2/3">
          <div className="flex items-center justify-between">
            <h1 className="flex text-4xl">
              <PlusIcon className="mr-3 w-6 text-slate-400" />
              Neue Alarmliste
            </h1>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="mt-16">
              <Formik
                initialValues={{
                  name: "",
                  userIds: [],
                }}
                onSubmit={async (values) => {
                  setLoading(true);
                  await dataService.createAlarmlist(
                    orgaId ?? "",
                    values.name,
                    values.userIds,
                  );
                  toast.success("Erfolgreich erstellt");
                  navigate("../alarmlist");
                }}
              >
                <Form>
                  <div className="mt-12 mb-2 flex items-center">
                    <p className="mr-6 text-xl font-semibold text-slate-700">
                      1. Allgemeine Informationen
                    </p>
                    <hr className="flex-grow" />
                  </div>
                  <label className="col-span-2 my-3 block">
                    <span className="text-xs font-bold text-slate-500">
                      Name*
                    </span>
                    <Field
                      required
                      autocomplete="alarmlist_name"
                      className={fieldStyling}
                      type="name"
                      name="name"
                      placeholder="Alarmliste #1"
                    />
                    <ErrorMessage
                      component="p"
                      className="mt-2 text-xs font-semibold text-red-400"
                      name="firstname"
                    />
                  </label>
                  <div className="mt-12 mb-2 flex items-center">
                    <p className=" mr-6 text-xl font-semibold text-slate-700">
                      2. Helferauswahl
                    </p>
                    <hr className="flex-grow" />
                  </div>
                  <label className="col-span-2 my-3 block">
                    <div className="flex grid-cols-12 items-center gap-4 align-middle">
                      <label className="col-span-8 my-3 block">
                        <Field
                          className={fieldStyling}
                          placeholder="Suche"
                          value={search}
                          onChange={filter}
                        />
                      </label>
                    </div>
                    <div className="flex flex-col">
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow-md sm:rounded-lg">
                            <table className="min-w-full ">
                              <thead className="bg-zinc-100 ">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                  >
                                    <input
                                      type="checkbox"
                                      onChange={checkAll}
                                    ></input>
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                  >
                                    Qualifikationen
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                  >
                                    Führerschein
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                  >
                                    Alter
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative py-3 px-6"
                                  >
                                    <span className="sr-only">Info</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="">
                                {availableUsers.map((user) => {
                                  let birthDate = new Date(
                                    user.birthyear ?? 0,
                                    user.birthmonth + 1 ?? 0,
                                    user.birthday ?? 0,
                                  );

                                  let ageString = "";
                                  let driversLicenses = "";

                                  user.DriverslicenseOnUsers?.forEach(
                                    (element) => {
                                      driversLicenses +=
                                        element.driverslicense.short + ", ";
                                    },
                                  );

                                  if (user.birthday) {
                                    let difference =
                                      Date.now() - birthDate.getTime();
                                    let ageDate = new Date(difference);
                                    let calculatedAge = Math.abs(
                                      ageDate.getUTCFullYear() - 1970,
                                    );

                                    ageString =
                                      calculatedAge +
                                      " (" +
                                      user.birthday +
                                      "." +
                                      user.birthmonth +
                                      "." +
                                      user.birthyear +
                                      ")";
                                  } else {
                                    ageString = "Kein Alter";
                                  }

                                  return (
                                    <tr
                                      className="border-b bg-white odd:bg-white even:bg-gray-50"
                                      key={user.id}
                                    >
                                      <td className="whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900">
                                        <Field
                                          type="checkbox"
                                          name="userIds"
                                          value={user.id}
                                        />
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 ">
                                        {user.lastname}
                                        {", "}
                                        <span className="text-slate-500">
                                          {user.firstname}
                                        </span>
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                        -
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                        {driversLicenses}
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                        {ageString}
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-6 text-right text-sm font-medium">
                                        <a
                                          title={
                                            user.firstname +
                                            " " +
                                            user.lastname +
                                            " anzeigen"
                                          }
                                          href="https://google.com"
                                          className="text-blue-500 transition-all  hover:text-blue-900"
                                        >
                                          <InformationCircleIcon className="h-4 w-4" />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                  <button
                    type="submit"
                    className="self-right my-16 flex items-center rounded-md bg-sky-700 px-6 py-2 text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900"
                  >
                    <SaveIcon className="mr-2 w-5" /> Speichern
                  </button>
                </Form>
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateAlarmlist;
