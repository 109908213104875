import "chart.js/auto";
import { HomeIcon } from "@heroicons/react/solid";
import { Doughnut } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";

export const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 0.5,
    },
  ],
};

function Dashboard() {
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="mt-12 w-2/3">
          <div className="flex items-center justify-between">
            <h1 className="flex text-4xl">
              <HomeIcon className="mr-3 w-6 text-slate-400" />
              Dashboard
            </h1>
          </div>
          <div className="mt-16">
            <p className="italic">- Nichts anzuzeigen -</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
