import { useLocation, Navigate } from "react-router-dom";
import authService from "./auth.service";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const isLoggedIn = authService.isLoggedIn();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
