import { BellIcon, XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Collapsible from "../../components/Collapsible";
import DividingHeader from "../../components/DividingHeader";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import AAO from "../../interfaces/AAO";
import Alarmlist from "../../interfaces/Alarmlist";
import dataService from "../../services/data.service";
function CreateAlarm() {
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="w-2/3 mt-12">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl flex">
              <BellIcon className="w-6 mr-3 text-red-400" />
              Neue Alarmierung
            </h1>
          </div>
          <div className="mt-16">
            <QuickAlarm />
          </div>
        </div>
      </div>
    </div>
  );
}

function QuickAlarm() {
  const [aaos, setAAOS] = useState<AAO[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAAO, setSelectedAAO] = useState<AAO>();

  const { orgaId } = useParams();

  async function fetchAAOs() {
    setLoading(true);
    let response = await dataService.getAllAAO(orgaId ?? "");

    if (response.status !== 200) {
      return toast.error("Ein Fehler ist aufgetreten");
    }

    response.data.forEach((aao: AAO) => {
      setAAOS((prevState) => [...prevState, aao]);
    });
    setLoading(false);
  }

  useEffect(() => {
    fetchAAOs();
  }, []);

  if (loading) {
    return (
      <>
        <div className="mt-12 mb-2 flex items-center">
          <p className=" text-slate-700 font-semibold text-xl mr-6">
            Schnellalarmierung
          </p>
          <hr className="flex-grow" />
        </div>
        <div className="flex justify-between">
          <div className="w-full flex items-center align-middle">
            <Loading />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {showModal && (
        <SendAlarmModal selectedAAO={selectedAAO} setShowModal={setShowModal} />
      )}
      <div className="mt-12 mb-2 flex items-center">
        <p className=" text-slate-700 font-semibold text-xl mr-6">
          Schnellalarmierung
        </p>
        <hr className="flex-grow" />
      </div>
      <div className="flex justify-between">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-2 w-full">
          {aaos.map((aao) => {
            return (
              <button
                onClick={() => {
                  setSelectedAAO(aao);
                  setShowModal(true);
                }}
                className="py-2 px-6 bg-white active:bg-zinc-200 hover:bg-zinc-100 shadow rounded-md transition-all hover:shadow-md active:shadow-lg"
              >
                {aao.name}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}

interface SendAlarmModalProps {
  selectedAAO: AAO | undefined;
  setShowModal: (open: boolean) => void;
}

function SendAlarmModal({ selectedAAO, setShowModal }: SendAlarmModalProps) {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [userCount, setUserCount] = useState(0);

  async function loadUserCount() {
    setLoading(true);
    let response = await dataService.getUserCountOnAAO(selectedAAO?.id ?? "");
    if (!response || response.status !== 200)
      return toast.error("Fehler beim Laden der Helferanzahl");
    setUserCount(response.data);

    setLoading(false);
  }

  async function sendAlarm() {
    let res = await dataService.sendAlarm(selectedAAO?.id ?? "", description);
    if (res.status !== 201) return toast.error("Fehler bei der Alarmierung");
    toast.success(selectedAAO?.name + " erfolgreich alarmiert");
    setShowModal(false);
  }

  useEffect(() => {
    loadUserCount();
  }, []);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl bg-white rounded shadow-md p-12">
          <p className="text-lg">
            Möchten Sie <b>{selectedAAO?.name}</b> alarmieren?
          </p>
          <p className="text-sm text-slate-400 mt-4">
            Beschreibung / Zusätzliche Informationen
          </p>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            maxLength={255}
            className="w-full px-3 py-2 border border-slate-200 rounded-md mt-2 focus:border-slate-500 transition-all"
          ></textarea>
          <button
            onClick={() => sendAlarm()}
            className="w-full bg-green-700 mt-12 text-white rounded-md px-6 py-2 flex items-center align-middle justify-center hover:shadow-md hover:bg-green-800 active:bg-green-900 transition-all text-sm"
          >
            {loading ? (
              <img
                src="/loading_white.svg"
                className="h-5 mr-2"
                alt="Ladeanimation"
              />
            ) : (
              <span className="flex mr-1">
                <BellIcon className="w-5 mr-1" /> <b>{userCount}</b>
              </span>
            )}
            Helfer Alarmieren
          </button>
          <button
            onClick={() => setShowModal(false)}
            className="w-full mt-2 border text-slate-400 rounded-md px-6 py-2 flex items-center align-middle justify-center hover:shadow-md transition-all text-sm"
          >
            <XIcon className="w-5 mr-2" /> Abbrechen
          </button>
        </div>
      </div>
      <div
        onClick={() => setShowModal(false)}
        className="opacity-25 fixed inset-0 z-40 bg-black"
      ></div>
    </>
  );
}

function CreateAlarmForm() {
  const { orgaId } = useParams();

  const [alarmlists, setAlarmlists] = useState<Alarmlist[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dataService.getAlarmlists(orgaId ?? "").then((res) => {
      if (res.status === 200) {
        res.data.forEach((alarmlist: Alarmlist) => {
          setAlarmlists((prevState) => [...prevState, alarmlist]);
        });

        setLoading(false);
      } else {
        alert("Fehler beim Laden (Fehler: " + res.status + ")");
      }
    });
  }, []);

  return (
    <>
      <DividingHeader title="Erweiterte Alarmierung" />
      <Collapsible title="- Nicht implementiert! -">
        <div className="flex gap-x-12 justify-between">
          <div className="grid grid-cols-12 gap-x-3 h-min">
            <div className="col-span-12">
              <p className="text-slate-500">Vorschau</p>
              <p className="font-mono">
                Alarm für die EE-NRW-ST-04 - BTP_500_NRW
              </p>
            </div>
          </div>
          <MapContainer
            center={[52.10884336886156, 7.606848737440892]}
            zoom={14}
            className="w-96 h-96 rounded-md shadow-sm hover:shadow-md transition-all"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[52.10884336886156, 7.606848737440892]}></Marker>
          </MapContainer>
        </div>
      </Collapsible>

      <button
        type="submit"
        className="bg-red-700 text-white rounded-md px-6 py-2 flex items-center hover:shadow-xl hover:bg-red-800 active:bg-red-900 transition-all text-sm my-16 self-right"
      >
        <BellIcon className="w-5 mr-2" /> Alarmieren
      </button>
    </>
  );
}

export default CreateAlarm;
