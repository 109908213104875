import { MailIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";

function RegisterError() {
  const navigate = useNavigate();
  return (
    <div className="flex w-full flex-col justify-between px-6 pt-36 pb-12 md:px-24 lg:w-1/2">
      {/** Message */}
      <div>
        <h1 className="mb-2 text-3xl font-bold text-slate-800 lg:text-4xl">
          Hoppala<span className="text-red-500">!</span>
        </h1>
        <p className="text-lg">
          Dein Einladungslink ist entweder abgelaufen oder ungültig.
        </p>
      </div>

      {/** Ufo */}
      <img src="/ufo.svg" width={250} alt="Bild eines UFO's" />
      {/** Actions */}
      <div className="">
        <div className="grid gap-4 text-sm md:grid-cols-1">
          <button className="flex items-center justify-center gap-2 rounded bg-blue-500 px-24 py-2 text-white transition-all hover:bg-blue-600 hover:shadow active:bg-blue-700 active:shadow-md">
            Zurück
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegisterError;
