import { KeyIcon } from "@heroicons/react/solid";
import { ErrorMessage, Field } from "formik";
import Navbar from "../../components/Navbar";

interface RegisterStepProps {
  onForward: () => void;
  onBackward: () => void;
}

function RegisterStep1({ onForward, onBackward }: RegisterStepProps) {
  return (
    <>
      <div className="flex w-full flex-col justify-between px-6 pt-36 pb-12 md:px-24 lg:w-1/2">
        {/** Titel */}
        <div className="mb-6">
          <h1 className="text-3xl font-bold text-slate-800 lg:text-4xl">
            Konto erstellen<span className="text-red-500">.</span>
          </h1>
          <div></div>
        </div>
        {/** Stepper & Form */}
        <div>
          <form className="grid grid-cols-6 gap-4">
            <div className="col-span-6 mb-3 flex flex-col">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                E-Mail
              </label>

              <div className="col-span-6 flex items-center ">
                <Field
                  disabled
                  type="email"
                  autoComplete="email"
                  className="min-w-full bg-transparent py-2 italic"
                  name="email"
                />
                <abbr
                  lang="de"
                  title="Wir verwenden automatisch die E-Mail mit der du eingeladen wurdest."
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 cursor-help text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </abbr>
              </div>
            </div>

            <div className="col-span-6 mb-3 flex w-full flex-col">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                <KeyIcon width={16} /> Passwort
              </label>
              <Field
                type="password"
                autoComplete="new-password"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="•••••••••"
                name="password"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="password"
              />
            </div>
            <div className="col-span-6 flex w-full flex-col">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                <KeyIcon width={16} /> Passwort wiederholen
              </label>
              <Field
                type="password"
                autoComplete="new-password"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="•••••••••"
                name="password_confirm"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="password_confirm"
              />
            </div>
          </form>
        </div>
        {/** Actions */}
        <div className="">
          <div className="mt-6 grid gap-4 text-sm md:grid-cols-2">
            <button
              onClick={() => onBackward()}
              className="rounded border border-slate-300 px-24 py-2 text-slate-500 transition-all hover:shadow active:shadow-md"
            >
              Zurück
            </button>
            <button
              onClick={() => onForward()}
              className="flex items-center justify-center gap-2 rounded bg-blue-500 px-24 py-2 text-white transition-all hover:bg-blue-600 hover:shadow active:bg-blue-700 active:shadow-md"
            >
              Weiter{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterStep1;
