import {
  BellIcon,
  CheckCircleIcon,
  ClockIcon,
  MapIcon,
  XIcon,
} from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import Alarm from "../../interfaces/Alarm";
import dataService from "../../services/data.service";

function AlarmDetail() {
  const { alarmId } = useParams();

  const [loading, setLoading] = useState(false);
  const [alarm, setAlarm] = useState<Alarm>();

  async function fetchData() {
    setLoading(true);
    let response = await dataService.getAlarm(alarmId ?? "");
    if (response.status !== 200)
      return toast.error("Fehler beim Laden der Daten");
    setAlarm(response.data as Alarm);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="mt-12 w-2/3">
          <div className="flex items-center justify-between">
            <h1 className="flex text-4xl">
              <BellIcon className="mr-3 w-6 text-slate-400" />
              Alarmdetailansicht
            </h1>
          </div>
          <div className="mt-16">
            {loading ? (
              <Loading />
            ) : (
              <div>
                <div className="px-6 py-3">
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                    <div>
                      <p className="text-sm text-slate-400">Stichwort</p>
                      <p>{alarm?.keyword}</p>
                    </div>

                    <div>
                      <p className="text-sm text-slate-400">
                        Beschreibung (Optional)
                      </p>
                      <p>{alarm?.description}</p>
                    </div>
                    <div>
                      <p className="text-sm text-slate-400">Status</p>
                      <p>{alarm?.state}</p>
                    </div>
                    <div>
                      <p className="text-sm text-slate-400">Erstellt am</p>
                      <p>{new Date(alarm?.createdAt ?? "").toLocaleString()}</p>
                    </div>
                    <div>
                      <p className="text-sm text-slate-400">
                        Zuletzt aktualisiert
                      </p>
                      <p>{new Date(alarm?.updatedAt ?? "").toLocaleString()}</p>
                    </div>

                    <div>
                      <p className="text-sm text-slate-400">ID</p>
                      <p>{alarm?.id}</p>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <p className="px-6 pt-3 pb-2 text-slate-400">
                    Helfer & Rückmeldungen
                  </p>
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow-md sm:rounded-lg">
                        <table className="min-w-full">
                          <tbody>
                            {alarm?.AlarmResponse?.map((alarmResponse) => {
                              let trailingElement;

                              if (alarmResponse.eta === "live") {
                                trailingElement = (
                                  <p className="flex align-middle ">
                                    {" "}
                                    <ClockIcon className="mr-2 h-5 text-green-500" />{" "}
                                    Ankunft in {alarmResponse.eta}
                                  </p>
                                );
                              } else if (
                                alarmResponse.eta !== "" &&
                                alarmResponse.responded === true
                              ) {
                                trailingElement = (
                                  <p className="flex items-center align-middle ">
                                    <ClockIcon className="mr-2 h-5 text-green-500" />{" "}
                                    Ankunft in {alarmResponse.eta}
                                  </p>
                                );
                              } else if (alarmResponse.declined) {
                                trailingElement = (
                                  <p className="flex items-center align-middle">
                                    <XIcon className="mr-2 h-5 text-red-500" />{" "}
                                    Einsatz abgelehnt
                                  </p>
                                );
                              } else if (alarmResponse.arrived) {
                                trailingElement = (
                                  <p className="flex items-center align-middle">
                                    <CheckCircleIcon className="mr-2 h-5 text-green-500" />{" "}
                                    Angekommen
                                  </p>
                                );
                              } else {
                                trailingElement = (
                                  <p className="flex items-center align-middle">
                                    <ClockIcon className="mr-2 h-5 text-slate-500" />{" "}
                                    Antwort ausstehend
                                  </p>
                                );
                              }

                              return (
                                <tr className="flex items-center justify-between border-b bg-white px-6 py-3 align-middle odd:bg-white even:bg-gray-50">
                                  <div>
                                    <p className="flex items-center align-middle">
                                      {/* Map Icon if Live */}
                                      {alarmResponse.eta === "live" && (
                                        <span title="Dieser Helfer sendet seinen Live-Standort">
                                          <MapIcon className="mr-1 h-4 cursor-help text-slate-600" />
                                        </span>
                                      )}{" "}
                                      {alarmResponse.user?.firstname}{" "}
                                      {alarmResponse.user?.lastname}
                                    </p>
                                    {alarmResponse.eta === "live" && (
                                      <p className="text-sm text-slate-400">
                                        zuletzt aktualisiert vor 2 Minuten
                                      </p>
                                    )}
                                  </div>
                                  {trailingElement}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlarmDetail;
