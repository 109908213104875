import { HomeIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function GenericHeader() {
  return (
    <div className="fixed flex h-16 w-screen items-center justify-between border-b-[1px] border-slate-200 bg-white bg-opacity-90 px-6 sm:px-8 md:px-16">
      <p className="text-slate-600">
        Einsatz<span className="text-red-500">Systeme</span>
      </p>
      <p>Systemkonfiguration</p>
      <Link to="../orga">
        <HomeIcon className="h-5 text-slate-600" />
      </Link>
    </div>
  );
}
