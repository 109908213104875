import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dataService from "../../services/data.service";
import { toast } from "react-toastify";
import User from "../../interfaces/User";
import Sidebar from "../../components/Sidebar";
import {
  BellIcon,
  DeviceMobileIcon,
  InformationCircleIcon,
  PencilIcon,
  SaveIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/solid";
import Loading from "../../components/Loading";
import RegisteredDevice from "../../interfaces/RegisteredDevice";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { LatLngExpression } from "leaflet";

function UserDetail() {
  const { orgaId, userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [registeredDevices, setRegisteredDevices] = useState<
    RegisteredDevice[]
  >([]);
  const [modal, setModal] = useState(false);
  const [deleteDeviceModal, setDeleteDeviceModal] = useState("");

  const [smsModal, setSmsModal] = useState(false);
  const smsMessage = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  async function fetchData() {
    //Check if Paramaters are given
    setLoading(true);
    if (!(orgaId && userId)) return;
    //Fetch User Data
    let userQuery = await dataService.getUser(orgaId, userId);
    if (userQuery.status !== 200) return toast.error("Fehler beim Laden");
    setUser(userQuery.data as User);

    //Fetch Device Data
    let deviceQuery = await dataService.fetchRegisteredDevices(userId);

    if (deviceQuery.status !== 200) return toast.error("Fehler beim Laden");

    setRegisteredDevices([]);
    deviceQuery.data.forEach((device: RegisteredDevice) => {
      setRegisteredDevices((prevState) => [...prevState, device]);
    });
    setLoading(false);
  }

  async function deleteUser() {
    setLoading(true);
    setModal(false);
    if (!(orgaId && userId)) return;

    let response = await dataService.removeUserFromOrganisation(userId, orgaId);

    if (response.status !== 200) return toast.error(response.data);
    toast.success("Nutzer erfolgreich entfernt");
    navigate("../users");
  }

  async function deleteDevice() {
    setLoading(true);
    setDeleteDeviceModal("");
    if (!(orgaId && userId)) return;

    let response = await dataService.deleteRegisteredDevice(deleteDeviceModal);

    if (response.status !== 200) return toast.error(response.data);
    toast.success("Gerät erfolgreich entfernt");
    fetchData();
  }

  async function sendSMS(message: string) {
    setSmsModal(false);
    setLoading(true);

    message = "[Test] " + message + " $t";

    let smsResponse = await dataService.sendSMS(user?.id!, message);

    if (smsResponse.status === 200) {
      toast.success("SMS erfolgreich versendet");
      setLoading(false);
    } else {
      toast.error("Fehler beim versenden der SMS");
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {modal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-auto max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-xl font-semibold">
                    {user!.firstname!} {user!.lastname!} aus Organisation
                    entfernen
                  </h3>
                  <button
                    className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black opacity-5 outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  ></button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  <p className="my-4">
                    Wenn Sie{" "}
                    <span className="font-bold">
                      {user!.firstname!} {user!.lastname!}
                    </span>{" "}
                    aus der Organisation entfernen werden sämtliche
                    Helferbezogenendaten gelöscht (Alarmrückmeldungen,
                    Qualifikationen, Adressdaten, etc.) Dieser Vorgang ist
                    unwideruflich. Um den Nutzer wieder hinzuzufügen muss dieser
                    über das jeweilige Menü erneut eingeladen werden.
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end rounded-b border-t border-solid border-slate-200 p-6">
                  <button
                    className="background-transparent mr-1 mb-1 px-6 py-2 text-sm text-blue-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                    type="button"
                    onClick={() => setModal(false)}
                  >
                    Abbrechen
                  </button>
                  <button
                    onClick={() => deleteUser()}
                    className="self-right flex items-center rounded-md bg-red-700 px-6 py-2 text-sm text-white transition-all hover:bg-red-800 hover:shadow-xl active:bg-red-900"
                  >
                    <SaveIcon className="mr-2 w-5" /> Entfernen
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
      {deleteDeviceModal !== "" ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-auto max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-xl font-semibold">
                    Gerät aus Organisation entfernen
                  </h3>
                  <button
                    className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black opacity-5 outline-none focus:outline-none"
                    onClick={() => setDeleteDeviceModal("")}
                  ></button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  <p className="my-4">
                    Wenn Sie dieses Geräte aus der Organisation entfernen kann
                    es nur durch erneutes Anmelden auf dem Gerät wieder
                    hinzugefügt werden. Dieser Vorgang ist unwideruflich
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end rounded-b border-t border-solid border-slate-200 p-6">
                  <button
                    className="background-transparent mr-1 mb-1 px-6 py-2 text-sm text-blue-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                    type="button"
                    onClick={() => setDeleteDeviceModal("")}
                  >
                    Abbrechen
                  </button>
                  <button
                    onClick={() => deleteDevice()}
                    className="self-right flex items-center rounded-md bg-red-700 px-6 py-2 text-sm text-white transition-all hover:bg-red-800 hover:shadow-xl active:bg-red-900"
                  >
                    <SaveIcon className="mr-2 w-5" /> Entfernen
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}

      {smsModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-auto max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-xl font-semibold">
                    SMS an {user!.firstname} {user!.lastname} versenden
                  </h3>
                  <button
                    className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black opacity-5 outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  ></button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  <p className="my-4">SMS eingeben</p>

                  <div className="flex flex-col">
                    <input
                      type="email"
                      className="rounded-lg border border-solid border-slate-200 p-2"
                      placeholder="Nachricht (max. 140 Zeichen)"
                      ref={smsMessage}
                      maxLength={140}
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end rounded-b border-t border-solid border-slate-200 p-6">
                  <button
                    className="background-transparent mr-1 mb-1 px-6 py-2 text-sm text-blue-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                    type="button"
                    onClick={() => setSmsModal(false)}
                  >
                    Abbrechen
                  </button>
                  <button
                    onClick={() => sendSMS(smsMessage.current!.value)}
                    className="self-right flex items-center rounded-md bg-blue-700 px-6 py-2 text-sm text-white transition-all hover:bg-blue-800 hover:shadow-xl active:bg-blue-900"
                  >
                    Versenden
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}

      <div className="flex">
        <Sidebar />
        <div className="flex flex-grow justify-center">
          <div className="mt-12 w-2/3">
            <div className="flex items-center justify-between">
              <h1 className="flex text-4xl">
                <PencilIcon className="mr-3 w-6 text-slate-400" />
                Detailansicht
              </h1>
            </div>
            <div className="mt-16">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className="mt-12 mb-2 flex items-center">
                    <p className=" mr-6 flex items-center text-xl font-semibold text-slate-700">
                      1. Allgemeine Informationen
                    </p>
                    <hr className="flex-grow" />
                  </div>
                  <div className="grid grid-cols-1 items-start gap-8 md:grid-cols-3">
                    <div className="flex flex-col">
                      <p className="text-xs font-bold text-slate-500">
                        Vorname
                      </p>
                      {user?.firstname}
                    </div>

                    <div className="flex flex-col">
                      <p className="text-xs font-bold text-slate-500">
                        Nachname
                      </p>
                      {user?.lastname}
                    </div>

                    <div className="flex flex-col">
                      <p className="text-xs font-bold text-slate-500">
                        Telefonnummer
                      </p>
                      <div className="flex flex-row items-center gap-4 align-middle">
                        {user?.phone}

                        <svg
                          onClick={() => setSmsModal(true)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-5 w-5 cursor-pointer"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                          />
                        </svg>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <p className="text-xs font-bold text-slate-500">
                        Passwort
                      </p>
                      <button className="mt-1 flex max-w-fit items-center  rounded-md border px-6 py-2 text-center align-middle text-sm transition-all hover:shadow">
                        <PencilIcon className="mr-2 w-5" /> Neues Passwort
                        vergeben
                      </button>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-xs font-bold text-slate-500">E-Mail</p>
                      {user?.email}
                    </div>

                    <div className="flex flex-col">
                      <p className="text-xs font-bold text-slate-500">
                        Geburtstag
                      </p>
                      {user?.birthday}.{user?.birthmonth}.{user?.birthyear}
                    </div>
                  </div>
                  <div className="mt-12 mb-2 flex items-center">
                    <p className=" mr-6 flex items-center text-xl font-semibold text-slate-700">
                      2. Kontakt
                    </p>
                    <hr className="flex-grow" />
                  </div>
                  <div className="mt-12 mb-2 flex items-center">
                    <p className=" mr-6 flex items-center text-xl font-semibold text-slate-700">
                      3. Alarmierung
                    </p>
                    <hr className="flex-grow" />
                  </div>
                  <div className="mt-12 mb-2 flex items-center">
                    <p className=" mr-6 flex items-center text-xl font-semibold text-slate-700">
                      3. Berechtigungen
                    </p>
                    <hr className="flex-grow" />
                  </div>
                  <BuildRegisteredDeviceTable
                    pRegisteredDevices={registeredDevices}
                    pSetDeleteDeviceModal={setDeleteDeviceModal}
                  />
                  <div className="mt-12 mb-2 flex items-center">
                    <p className=" mr-6 flex items-center text-xl font-semibold text-slate-700">
                      5. Qualifikationen
                    </p>
                    <hr className="flex-grow" />
                  </div>
                  <p className="text-sm text-slate-400">
                    Erstellt am {new Date(user!.createdAt!).toLocaleString()} -
                    Zuletzt bearbeitet:{" "}
                    {new Date(user!.updatedAt!).toLocaleString()}
                  </p>
                  <div className="flex justify-between">
                    <button
                      type="submit"
                      className="self-right my-6 flex items-center rounded-md bg-sky-700 px-6 py-2 text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900"
                    >
                      <SaveIcon className="mr-2 w-5" /> Speichern
                    </button>
                    <button
                      onClick={() => setModal(true)}
                      className="active:bg-sredky-900 self-right my-6 flex items-center rounded-md bg-red-700 px-6 py-2 text-sm text-white transition-all hover:bg-red-800 hover:shadow-xl"
                    >
                      <TrashIcon className="mr-2 w-5" /> Aus Organisation
                      entfernen
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface DeviceTableProps {
  pRegisteredDevices: RegisteredDevice[];
  pSetDeleteDeviceModal: React.Dispatch<React.SetStateAction<string>>;
}

function BuildRegisteredDeviceTable({
  pRegisteredDevices,
  pSetDeleteDeviceModal,
}: DeviceTableProps) {
  async function sendAlarmToUser(pFCMToken: string) {
    let confirm = await window.confirm(
      "Möchten Sie wirklich einen Probealarm an dieses Gerät senden?",
    );
    if (!confirm) return;
    var response = await dataService.sendAlarmToUser(pFCMToken);
    if (response.status !== 200) toast.error("Fehler beim alarmieren");
    else toast.success("Erfolgreich alarmiert");
  }

  return (
    <div>
      <div className="mt-6 mb-2 flex items-center">
        <p className=" mr-6 flex items-center text-xl font-semibold text-slate-700">
          <DeviceMobileIcon className="mr-2 h-4" /> Registrierte Geräte
        </p>
        <hr className="flex-grow" />
      </div>
      {pRegisteredDevices.length === 0 && (
        <p className="flex items-center ">
          <XIcon className="mr-2 h-6 text-red-400" />
          Keine anzuzeigenden Geräte gefunden
        </p>
      )}
      {pRegisteredDevices.map((device) => (
        <div className="my-4 flex justify-between rounded bg-white p-4 shadow">
          <div className="flex flex-row">
            <div className="flex flex-col align-middle">
              <div className="flex items-center align-middle">
                {device.name}{" "}
                <span title="Gerätename kann nur in der App geändert werden.">
                  <InformationCircleIcon className="ml-2 h-4 cursor-help text-slate-500 transition-all hover:text-slate-900" />
                </span>
              </div>

              {!device.receivesAlarms && (
                <p className="text-sm uppercase text-red-500">
                  Dieses Gerät hat Alarmierungen ausgestellt.
                </p>
              )}

              {}

              {device.current_lat && device.current_lng && (
                <div className="h-64">
                  {renderMap(
                    parseFloat(device.current_lat),
                    parseFloat(device.current_lng),
                  )}
                </div>
              )}

              <p className="text-sm text-slate-500">
                {device.manufacturer} {device.model} ({device.os}) - Letzter
                Login {new Date(device.updatedAt).toLocaleString()}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2 align-middle">
            <button
              title="Probealarm an dieses Gerät senden"
              className="text-blue-600 transition-all hover:text-blue-900"
              onClick={() => sendAlarmToUser(device.fcmToken)}
            >
              <BellIcon className="h-5" />
            </button>
            <button
              onClick={() => pSetDeleteDeviceModal(device.id)}
              className="mr-4"
              title="Gerät entfernen"
            >
              <XIcon className="h-5 text-red-500 transition-all hover:text-red-900" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

function renderMap(lat: number, lng: number) {
  const position: LatLngExpression = [lat, lng];

  return (
    <MapContainer center={position} zoom={13} className="h-64">
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>Geräteposition</Popup>
      </Marker>
    </MapContainer>
  );
}

export default UserDetail;
