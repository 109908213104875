import axios from "axios";
import Alarmlist from "../interfaces/Alarmlist";
import User from "../interfaces/User";
import authHeader from "./auth-header";
import authService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({ withCredentials: true });

function refreshAccessTokenInterceptor() {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status !== 401) return Promise.reject(error);

      axiosInstance.interceptors.response.eject(interceptor);

      return axiosInstance
        .post(`${API_URL}/auth/refresh`)
        .then(() => {
          return axiosInstance(error.response.config);
        })
        .catch(() => {
          window.location.href = "/login";
          return alert("Bitte erneut einloggen.");
        })
        .finally(() => refreshAccessTokenInterceptor());
    },
  );
}

class DataService {
  constructor() {
    refreshAccessTokenInterceptor();
  }

  getOrgas() {
    let _userId = authService.getCurrentUser()?.id;
    return axiosInstance.get(`${API_URL}/user/${_userId}/orgas`);
  }

  getUsers(orgaId: string) {
    return axiosInstance.get(`${API_URL}/organization/${orgaId}/users`);
  }

  registerUser(pUser: User, pInviteCode: string) {
    return axiosInstance.post(`${API_URL}/user`, {
      ...pUser,
      token: pInviteCode,
    });
  }

  getUser(orgaId: string, userId: string) {
    return axiosInstance.get(`${API_URL}/user/${userId}`);
  }

  getAlarmlists(orgaId: string) {
    return axiosInstance.get(`${API_URL}/alarmlist/orga/${orgaId}`);
  }

  getInviteCodeData(token: string) {
    return axiosInstance.get(`${API_URL}/invite/${token}`, {});
  }

  getAlarmlist(orgaId: string, alarmlistId: string) {
    return axiosInstance.get(
      `${API_URL}/alarmlist/orga/${orgaId}/${alarmlistId}`,
    );
  }

  deleteAlarmlist(alarmlistId: string) {
    return axiosInstance.delete(`${API_URL}/alarmlist/${alarmlistId}`);
  }

  getAllAAO(orgaId: string) {
    return axiosInstance.get(`${API_URL}/organization/${orgaId}/aao`);
  }

  createAAO(pName: string, pOrgaId: string, pAlarmlistIds: Array<string>) {
    return axiosInstance.post(`${API_URL}/aao`, {
      orgaId: pOrgaId,
      description: "Test",
      name: pName,
      alarmlistIds: pAlarmlistIds,
    });
  }

  getOrganization(orgaId: string) {
    return axiosInstance.get(`${API_URL}/organization`);
  }

  createAlarmlist(pOrgaId: string, pName: string, pUserIds: Array<string>) {
    return axiosInstance.post(`${API_URL}/alarmlist/orga/${pOrgaId}`, {
      name: pName,
      userIds: pUserIds,
    });
  }

  updateAlarmlist(pAlarmlist: Alarmlist, pUsers: String[]) {
    return axiosInstance.patch(`${API_URL}/alarmlist/` + pAlarmlist.id, {
      userIds: pUsers,
    });
  }

  deleteRegisteredDevice(pDeviceId: string) {
    return axiosInstance.delete(
      `${API_URL}/registeredDevice/device/${pDeviceId}`,
    );
  }

  fetchRegisteredDevices(pUserId: string) {
    return axiosInstance.get(`${API_URL}/registeredDevice/user/${pUserId}`);
  }

  removeUserFromOrganisation(pUserId: string, pOrgaId: string) {
    return axiosInstance.delete(
      `${API_URL}/organization/${pOrgaId}/users/${pUserId}`,
    );
  }

  sendAlarmToUser(pFCMToken: string) {
    return axiosInstance.post(`${API_URL}/registeredDevice/`, {
      fcmToken: pFCMToken,
    });
  }

  getAAO(pAAOId: string) {
    return axiosInstance.get(`${API_URL}/aao/${pAAOId}`);
  }

  getAllAlarms(pOrgaId: string) {
    return axiosInstance.get(`${API_URL}/organization/${pOrgaId}/alarms`);
  }

  getAlarm(pAlarmId: string) {
    return axiosInstance.get(`${API_URL}/alarm/${pAlarmId}`);
  }

  getUserCountOnAAO(pAaoId: string) {
    return axiosInstance.get(`${API_URL}/aao/${pAaoId}/count`);
  }

  updateAAO(pAaoId: string, pName: string, pAlarmlistIds: string[]) {
    return axiosInstance.patch(`${API_URL}/aao/${pAaoId}`, {
      name: pName,
      alarmlistIds: pAlarmlistIds,
    });
  }

  deleteAlarm(pAlarmId: string) {
    return axiosInstance.delete(`${API_URL}/alarm/${pAlarmId}`);
  }

  verifyUser(pOTP: string) {
    return axiosInstance.post(`${API_URL}/user/verify/`, { verify_code: pOTP });
  }

  inviteUser(pEmail: string, pOrgaId: string) {
    return axiosInstance.post(`${API_URL}/invite/`, {
      email: pEmail,
      orgaId: pOrgaId,
    });
  }

  deleteInvite(token: String) {
    return axiosInstance.delete(`${API_URL}/invite/${token}`);
  }

  sendAlarm(pAaoId: string, pDescription: string) {
    return axiosInstance.post(`${API_URL}/aao/${pAaoId}/alarm`, {
      description: pDescription,
    });
  }

  sendSMS(userId: string, message: string) {
    return axiosInstance.post(`${API_URL}/user/${userId}/sms`, {
      message: message,
    });
  }

  /**LANDLORD */
  landlordGetOrganisations() {
    return axiosInstance.get(`${API_URL}/landlord/organization`);
  }

  landlordCreateOrganization(values: any) {
    return axiosInstance.post(`${API_URL}/landlord/organization`, {
      ...values,
    });
  }
}

export default new DataService();
