import { MailIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";

interface RegisterStepProps {
  onForward: () => void;
  onBackward: () => void;

  orgaName: string;
  orgaImage: string;
}

function RegisterStep0({
  onForward,
  onBackward,
  orgaName,
  orgaImage,
}: RegisterStepProps) {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex w-full flex-col justify-between px-6 pt-36 pb-12 md:px-24 lg:w-1/2">
        {/** Company Picture */}
        <img src="/logo_mhd.jpg" alt="Organisations Logo" className="w-48" />
        {/** Stepper & Form */}
        <div>
          <h1 className="text-3xl font-bold text-slate-800 lg:text-4xl">
            Hallo<span className="text-red-500">.</span>
          </h1>
          <p>
            <span className="font-semibold">{orgaName}</span> nutzt Einsatz
            <span className="text-red-500">Alarm</span>. <br />
            Um alarmiert werden zu können benötigst du ein Einsatz
            <span className="text-red-500">Systeme</span> Konto.
          </p>
        </div>

        {/** Actions */}
        <div className="">
          <div className="grid gap-4 text-sm md:grid-cols-2">
            <button
              onClick={() => navigate("/login")}
              className="flex justify-center rounded border border-slate-300 px-24 py-2 text-slate-500 transition-all hover:shadow active:shadow-md"
            >
              Einloggen
            </button>
            <button
              onClick={() => onForward()}
              className="flex items-center justify-center gap-2 rounded bg-blue-500 px-24 py-2 text-white transition-all hover:bg-blue-600 hover:shadow active:bg-blue-700 active:shadow-md"
            >
              Registrieren{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <p className="mt-2 text-xs text-slate-500">
            Mit "Registrieren" akzeptierst du die{" "}
            <a
              className="underline"
              href="https://einsatz-status.net"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              AGB
            </a>{" "}
            und die{" "}
            <a
              className="underline"
              href="https://einsatz-status.net"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Datenschutzerklärung
            </a>{" "}
            von EinsatzSysteme.
          </p>
        </div>
      </div>
    </>
  );
}

export default RegisterStep0;
