import axios from "axios";
import Orga from "../interfaces/Orga";
import User from "../interfaces/User";

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  currentOrga: Orga | null;

  constructor() {
    this.currentOrga = null;
  }

  async login(email: String, password: String): Promise<string> {
    try {
      let authRes = await axios.post(
        `${API_URL}/auth/login`,
        {
          email,
          password,
        },
        { withCredentials: true },
      );
      if (authRes.data) {
        localStorage.setItem("user", JSON.stringify(authRes.data));
        return "";
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return err.response.data["message"];
      }
    }
    return "";
  }

  logout = () => {
    localStorage.removeItem("user");
    window.location.href = "/login";
  };

  getCurrentUser() {
    if (localStorage.getItem("user") != null) {
      let json = JSON.parse(localStorage.getItem("user") ?? "{}");
      return json as User;
    }
    return null;
  }

  setCurrentOrga(orga: Orga) {
    this.currentOrga = orga;
  }

  isLoggedIn() {
    if (localStorage.getItem("user") != null) {
      return true;
    }
    return false;
  }
}

export default new AuthService();
