import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <div className="bg-gray-50 text-slate-800 h-fit min-h-screen antialiased">
        <Outlet />
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={3}
        draggable
        className="text-slate-700 text-sm"
      />
    </>
  );
}

export default App;
